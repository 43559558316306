import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="100%" width="100%" viewBox="0 0 512 512" {...props}>
      <path fill="#2D2D2D" d="M384,512c8.448,0,15.36-6.912,15.36-15.36V15.36C399.36,6.912,392.448,0,384,0H128
	c-8.448,0-15.36,6.912-15.36,15.36v481.28c0,8.448,6.912,15.36,15.36,15.36L384,512L384,512z M286.72,481.28h-61.44
	c-2.816,0-5.12-2.304-5.12-5.12s2.304-5.12,5.12-5.12h61.44c2.816,0,5.12,2.304,5.12,5.12S289.536,481.28,286.72,481.28z
	 M304.552,15.52c2.832,0,5.128,2.296,5.128,5.128s-2.296,5.128-5.128,5.128s-5.128-2.296-5.128-5.128S301.72,15.52,304.552,15.52z
	 M286.928,15.52c2.832,0,5.128,2.296,5.128,5.128s-2.296,5.128-5.128,5.128s-5.128-2.296-5.128-5.128S284.096,15.52,286.928,15.52z
	 M207.44,15.52h45.44c2.816,0,5.12,2.304,5.12,5.12s-2.304,5.12-5.12,5.12h-45.44c-2.816,0-5.12-2.304-5.12-5.12
	S204.624,15.52,207.44,15.52z M128,281.768V264.8V66.024V49.056v-8.48h256v12.32v16.968v235.184v16.968v128.16H156.92h-16.968H128
	V281.768z"/>
<g>
	<path fill="#C9C9C9" d="M286.72,471.04h-61.44c-2.816,0-5.12,2.304-5.12,5.12s2.304,5.12,5.12,5.12h61.44
		c2.816,0,5.12-2.304,5.12-5.12S289.536,471.04,286.72,471.04z"/>
	<path fill="#C9C9C9" d="M207.44,25.76h45.44c2.816,0,5.12-2.304,5.12-5.12s-2.304-5.12-5.12-5.12h-45.44
		c-2.816,0-5.12,2.304-5.12,5.12S204.624,25.76,207.44,25.76z"/>
	<circle fill="#C9C9C9" cx="286.928" cy="20.648" r="5.128"/>
	<circle fill="#C9C9C9" cx="304.56" cy="20.648" r="5.128"/>
</g>
<g>
	<polygon fill="#8AD5DD" points="156.92,450.176 384,450.176 384,322.024 334.536,272.56 	"/>
	<polygon fill="#8AD5DD" points="384,52.896 384,40.576 128,40.576 128,49.056 257.92,178.976 	"/>
	<polygon fill="#8AD5DD" points="384,305.056 384,69.864 266.408,187.456 	"/>
	<polygon fill="#8AD5DD" points="326.056,264.08 128,66.024 128,264.8 226.664,363.464 	"/>
	<polygon fill="#8AD5DD" points="218.184,371.944 128,281.768 128,450.176 139.952,450.176 	"/>
</g>
<polygon fill="#FFFFFF" points="139.952,450.176 156.92,450.176 334.536,272.56 384,322.024 384,305.056 266.408,187.456 
	384,69.864 384,52.896 257.92,178.976 128,49.056 128,66.024 326.056,264.08 226.664,363.464 128,264.8 128,281.768 
	218.184,371.944 "/>
    </svg>
  );
}

export default SvgComponent;
