import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="100%" width="100%" viewBox="0 0 512 512" {...props}>
<path fill="#DB2B42" d="M256,0C114.616,0,0,114.616,0,256s114.616,256,256,256s256-114.616,256-256S397.384,0,256,0z
	 M191.752,215.344l-98.728,43.24v0.544l98.728,43.24V331.2L61.2,270.552V247.16l130.544-60.648v28.832H191.752z M239.376,342.088
	h-27.472l58.2-197.184h27.472L239.376,342.088z M443.912,271.368L313.368,331.2v-28.832l100.904-43.24v-0.544l-100.904-43.24
	v-28.832l130.544,59.832L443.912,271.368L443.912,271.368z"/>
<g>
	<polygon fill="#8AD5DD" points="61.2,270.552 191.752,331.2 191.752,302.376 93.024,259.136 93.024,258.584 
		191.752,215.344 191.752,186.512 61.2,247.168 	"/>
	<polygon fill="#8AD5DD" points="211.904,342.088 239.376,342.088 297.576,144.896 270.112,144.896 	"/>
	<polygon fill="#8AD5DD" points="313.368,215.344 414.272,258.584 414.272,259.136 313.368,302.376 313.368,331.2 
		443.912,271.368 443.912,246.352 313.368,186.512 	"/>
</g>
    </svg>
  );
}

export default SvgComponent;
