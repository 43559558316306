import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="100%" width="100%" viewBox="0 0 512 512" {...props}>
<path fill="#2D2D2D" d="M71.68,512h256c8.448,0,15.36-6.912,15.36-15.36V360.728c0.992-0.632,2.048-1.184,3.032-1.84
	c7.144-4.824,13.8-10.312,19.904-16.36c25.264-24.984,40.944-59.64,40.944-97.984c0-48.88-25.512-91.704-63.88-116.176V15.36
	c0-8.448-6.912-15.36-15.36-15.36h-256c-8.448,0-15.36,6.912-15.36,15.36v481.28C56.32,505.088,63.232,512,71.68,512z M230.4,481.28
	h-61.44c-2.816,0-5.12-2.304-5.12-5.12s2.304-5.12,5.12-5.12h61.44c2.816,0,5.12,2.304,5.12,5.12S233.216,481.28,230.4,481.28z
	 M392.472,244.552c0,68.144-55.24,123.384-123.384,123.384s-123.384-55.24-123.384-123.384s55.24-123.384,123.384-123.384
	S392.472,176.408,392.472,244.552z M248.232,15.52c2.832,0,5.128,2.296,5.128,5.128s-2.296,5.128-5.128,5.128
	s-5.128-2.296-5.128-5.128S245.4,15.52,248.232,15.52z M230.608,15.52c2.832,0,5.128,2.296,5.128,5.128s-2.296,5.128-5.128,5.128
	s-5.128-2.296-5.128-5.128S227.776,15.52,230.608,15.52z M151.12,15.52h45.44c2.816,0,5.12,2.304,5.12,5.12s-2.304,5.12-5.12,5.12
	h-45.44c-2.816,0-5.12-2.304-5.12-5.12S148.304,15.52,151.12,15.52z M71.68,40.624h256v79.312
	c-17.8-8.384-37.608-13.208-58.584-13.208c-76.12,0-137.824,61.704-137.824,137.824s61.704,137.824,137.824,137.824
	c20.976,0,40.784-4.824,58.584-13.208v81.064h-256V40.624z"/>
<g>
	<path fill="#C9C9C9" d="M230.4,471.04h-61.44c-2.816,0-5.12,2.304-5.12,5.12s2.304,5.12,5.12,5.12h61.44
		c2.816,0,5.12-2.304,5.12-5.12S233.216,471.04,230.4,471.04z"/>
	<path fill="#C9C9C9" d="M151.12,25.76h45.44c2.816,0,5.12-2.304,5.12-5.12s-2.304-5.12-5.12-5.12h-45.44
		c-2.816,0-5.12,2.304-5.12,5.12S148.304,25.76,151.12,25.76z"/>
	<circle fill="#C9C9C9" cx="230.608" cy="20.648" r="5.128"/>
	<circle fill="#C9C9C9" cx="248.24" cy="20.648" r="5.128"/>
</g>
<path fill="#8AD5DD" d="M327.68,369.168c-17.8,8.384-37.608,13.208-58.584,13.208c-76.12,0-137.824-61.704-137.824-137.824
	s61.704-137.824,137.824-137.824c20.976,0,40.784,4.824,58.584,13.208V40.624h-256v409.6h256V369.168z"/>
<g>
	<polygon fill="#DB2B42" points="378.408,417.296 410.776,449.656 455.68,406.176 422.608,373.096 422.608,373.096 
		422.608,373.096 	"/>
	<path fill="#DB2B42" d="M365.8,378.616l18.128-18.128l-17.96-17.96c-6.112,6.04-12.76,11.536-19.904,16.36L365.8,378.616z"
		/>
</g>
<polygon fill="#2D2D2D" points="352.768,391.648 378.408,417.296 378.408,417.296 422.608,373.096 422.608,373.096 
	396.96,347.456 383.928,360.488 365.8,378.616 "/>
<path fill="#FFFFFF" d="M269.096,121.168c-68.144,0-123.384,55.24-123.384,123.384s55.24,123.384,123.384,123.384
	s123.384-55.24,123.384-123.384S337.232,121.168,269.096,121.168z M218.312,270.44h-7.36l-6.8-21.24
	c-1.568-4.968-2.856-9.376-3.952-14.624h-0.184c-1.104,5.336-2.48,9.928-4.04,14.712l-7.168,21.144h-7.36l-13.416-44.496h8.272
	l5.88,22.616c1.28,4.96,2.48,9.552,3.312,14.16h0.28c1.008-4.512,2.48-9.288,3.952-14.064l7.264-22.712h6.8l6.896,22.248
	c1.656,5.328,2.944,10.016,3.952,14.528h0.28c0.736-4.512,1.928-9.2,3.4-14.432l6.336-22.336h8L218.312,270.44z M287.064,270.44
	h-7.36l-6.8-21.24c-1.568-4.968-2.856-9.376-3.952-14.624h-0.184c-1.104,5.336-2.48,9.928-4.04,14.712l-7.168,21.144h-7.36
	l-13.416-44.496h8.272l5.88,22.616c1.28,4.96,2.48,9.552,3.312,14.16h0.28c1.008-4.512,2.48-9.288,3.952-14.064l7.264-22.712h6.8
	l6.896,22.248c1.656,5.328,2.944,10.016,3.952,14.528h0.28c0.736-4.512,1.928-9.2,3.4-14.432l6.336-22.336h8L287.064,270.44z
	 M341.288,225.944l6.896,22.248c1.656,5.328,2.944,10.016,3.952,14.528h0.28c0.736-4.512,1.928-9.2,3.4-14.432l6.336-22.336h8
	l-14.344,44.496h-7.36l-6.8-21.24c-1.568-4.968-2.856-9.376-3.952-14.624h-0.176c-1.104,5.336-2.48,9.928-4.04,14.712l-7.168,21.144
	h-7.36l-13.416-44.496h8.272l5.88,22.616c1.28,4.96,2.48,9.552,3.312,14.16h0.28c1.008-4.512,2.48-9.288,3.952-14.064l7.264-22.712
	H341.288z"/>
<g>
	<path fill="#DB2B42" d="M218.312,248.28c-1.472,5.24-2.664,9.928-3.4,14.432h-0.272c-1.008-4.512-2.296-9.2-3.952-14.528
		l-6.896-22.248h-6.8l-7.264,22.712c-1.472,4.776-2.936,9.552-3.952,14.064h-0.28c-0.832-4.6-2.024-9.2-3.312-14.16l-5.88-22.616
		h-8.272l13.416,44.496h7.36l7.168-21.144c1.568-4.784,2.936-9.376,4.04-14.712h0.184c1.104,5.24,2.392,9.656,3.952,14.624
		l6.8,21.24h7.36l14.344-44.496h-8L218.312,248.28z"/>
	<path fill="#DB2B42" d="M287.064,248.28c-1.472,5.24-2.664,9.928-3.4,14.432h-0.28c-1.008-4.512-2.296-9.2-3.952-14.528
		l-6.896-22.248h-6.8l-7.264,22.712c-1.472,4.776-2.936,9.552-3.952,14.064h-0.28c-0.832-4.6-2.024-9.2-3.312-14.16l-5.88-22.616
		h-8.272l13.416,44.496h7.36l7.168-21.144c1.568-4.784,2.936-9.376,4.04-14.712h0.184c1.104,5.24,2.392,9.656,3.952,14.624
		l6.8,21.24h7.36l14.344-44.496h-8L287.064,248.28z"/>
	<path fill="#DB2B42" d="M327.224,248.656c-1.472,4.776-2.936,9.552-3.952,14.064h-0.28c-0.832-4.6-2.024-9.2-3.312-14.16
		l-5.88-22.616h-8.272l13.416,44.496h7.36l7.168-21.144c1.568-4.784,2.936-9.376,4.04-14.712h0.184
		c1.104,5.24,2.392,9.656,3.952,14.624l6.8,21.24h7.36l14.344-44.496h-8l-6.336,22.336c-1.472,5.24-2.664,9.928-3.4,14.432h-0.28
		c-1.008-4.512-2.296-9.2-3.952-14.528l-6.896-22.248h-6.8L327.224,248.656z"/>
</g>
</svg>
  );
}

export default SvgComponent;
