import React from 'react';
import Landing from "./screens/Landing.jsx";
class App extends React.Component {
  render(){
    return (
      <Landing/>
    );
  }
}

export default App;
