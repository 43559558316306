import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="100%" width="100%" viewBox="0 0 512 512" {...props}>
<path fill="#2D2D2D" d="M501.552,20.896H10.448C4.704,20.896,0,25.6,0,31.344v323.92h512V31.344
	C512,25.6,507.296,20.896,501.552,20.896z M491.104,334.368H20.896V41.792h470.2v292.576H491.104z"/>
<g>
	<path fill="#E8E8E8" d="M0,397.064c0,5.744,4.704,10.448,10.448,10.448h182.856h125.392h182.856
		c5.744,0,10.448-4.704,10.448-10.448v-41.8H0V397.064z"/>
	<polygon fill="#E8E8E8" points="146.28,480.648 146.28,491.104 365.712,491.104 365.712,480.648 329.144,480.648 
		182.856,480.648 	"/>
</g>
<polygon fill="#C9C9C9" points="182.856,480.648 329.144,480.648 318.696,407.512 193.304,407.512 "/>
<path fill="#8AD5DD" d="M20.896,334.368h470.2V41.792h-470.2V334.368z M299.944,188.08c0-5.216,0.88-10.224,2.432-14.92
	l-79.856-39.408c-8.584,12.08-22.656,19.992-38.608,19.992c-26.16,0-47.36-21.208-47.36-47.36s21.208-47.36,47.36-47.36
	c26.16,0,47.36,21.208,47.36,47.36c0,4.496-0.664,8.824-1.84,12.944l80.504,39.72c8.664-11.136,22.16-18.328,37.368-18.328
	c26.16,0,47.36,21.208,47.36,47.36c0,26.16-21.208,47.36-47.36,47.36c-16.264,0-30.608-8.208-39.136-20.696L229.272,256.2
	c1.288,4.304,2,8.848,2,13.568c0,26.16-21.208,47.36-47.36,47.36c-26.16,0-47.36-21.208-47.36-47.36
	c0-26.16,21.208-47.36,47.36-47.36c15.72,0,29.608,7.688,38.224,19.48l79.416-41.736
	C300.536,196.296,299.944,192.264,299.944,188.08z"/>
<g>
	<path fill="#2D2D2D" d="M229.432,119.328c-1.488,5.24-3.84,10.096-6.92,14.424l79.856,39.408
		c1.712-5.16,4.296-9.912,7.56-14.112L229.432,119.328z"/>
	<path fill="#2D2D2D" d="M229.272,256.208l78.896-41.456c-2.992-4.384-5.224-9.32-6.616-14.592l-79.416,41.736
		C225.272,246.184,227.72,250.992,229.272,256.208z"/>
</g>
<path fill="#DB2B42" d="M347.304,235.44c26.16,0,47.36-21.208,47.36-47.36c0-26.16-21.208-47.36-47.36-47.36
	c-15.208,0-28.704,7.192-37.368,18.328c-3.272,4.2-5.848,8.952-7.56,14.112c-1.56,4.696-2.432,9.704-2.432,14.92
	c0,4.184,0.6,8.216,1.616,12.08c1.392,5.272,3.624,10.208,6.616,14.592C316.696,227.24,331.04,235.44,347.304,235.44z
	 M347.304,167.232c11.512,0,20.84,9.336,20.84,20.84c0,11.512-9.336,20.84-20.84,20.84s-20.84-9.336-20.84-20.84
	C326.456,176.568,335.792,167.232,347.304,167.232z"/>
<circle fill="#EA5071" cx="347.28" cy="188.08" r="20.84"/>
<path fill="#DB2B42" d="M231.272,106.384c0-26.16-21.208-47.36-47.36-47.36c-26.16,0-47.36,21.208-47.36,47.36
	s21.208,47.36,47.36,47.36c15.952,0,30.024-7.912,38.608-19.992c3.072-4.328,5.432-9.184,6.92-14.424
	C230.608,115.208,231.272,110.88,231.272,106.384z M163.064,106.384c0-11.512,9.336-20.84,20.84-20.84
	c11.512,0,20.84,9.328,20.84,20.84s-9.336,20.84-20.84,20.84C172.4,127.224,163.064,117.896,163.064,106.384z"/>
<circle fill="#EA5071" cx="183.912" cy="106.384" r="20.84"/>
<path fill="#DB2B42" d="M183.912,222.416c-26.16,0-47.36,21.208-47.36,47.36c0,26.16,21.208,47.36,47.36,47.36
	c26.16,0,47.36-21.208,47.36-47.36c0-4.72-0.712-9.264-2-13.568c-1.56-5.216-4.008-10.024-7.144-14.312
	C213.52,230.104,199.632,222.416,183.912,222.416z M183.912,290.624c-11.512,0-20.84-9.336-20.84-20.84
	c0-11.512,9.336-20.84,20.84-20.84c11.512,0,20.84,9.336,20.84,20.84S195.424,290.624,183.912,290.624z"/>
<circle fill="#EA5071" cx="183.912" cy="269.776" r="20.84"/>
</svg>
  );
}

export default SvgComponent;
